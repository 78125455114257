import { Userv2 } from "models/user";
import getConfig from "next/config";
import { USER_ENDPOINT } from "./endpoints";

const user = process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_API_USER! : "3861d15a-a19c-46a4-a52d-318e876d374c" // '990f7360-70a8-4d10-9c64-8f82083ab96f'

export async function fetchCurrentUser(fetchFn = fetch): Promise<Userv2> {
  const resp = await fetchFn(USER_ENDPOINT('@me'))
  if (!resp.ok) throw Error('could not fetch current user')
  return (await resp.json()) as Userv2
}

export type CurrentUserProps = {
  currentUser?: Userv2
}
