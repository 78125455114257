import "../styles/globals.css";
import '../components/editor/TiptapTheme.scss'
import type { AppProps } from "next/app";
import { Navbar } from "components/Navbar";
import Head from "next/head";
// import "@szhsin/react-menu/dist/core.css";
import '../styles/react-menu.css';
import Modal from 'react-modal'
import 'react-toastify/dist/ReactToastify.css';
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import { ChangelogModal, ChangelogModalStateContext } from "components/Changelog";
import { useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { SkeletonTheme } from "react-loading-skeleton";
import { ReactQueryDevtools } from 'react-query/devtools'
import { NewlyRegisteredPopup } from "components/NewlyRegisteredPopup";
import { AuthProvider } from "api/auth";
// import { AuthProvider } from "hooks/auth";
Modal.setAppElement('#__next')

// export function getInitialProps(): any {
//   return {

//   }
// }

function App({ Component, pageProps }: AppProps) {
  const client = new QueryClient({defaultOptions: {
    queries: {
      retry: 10,
    }
  }})
  const changelogState = useState(false)
  return (
    <>
      <Head key="head">
        <title>Research Helper</title>
        <meta name="keywords" content="medicine, articles, медицина, статьи, кардиология, ропниз, нмиц тпм" />
        <meta name="description" content="Первая в России платформа, созданная для подачи, экспертизы и публикации научно-методической медицинской информации в одно касание" />
        <meta name="og:title" content="Research Helper" />
        <meta name="og:description" content="Первая в России платформа, созданная для подачи, экспертизы и публикации научно-методической медицинской информации в одно касание" />
        <meta name="theme-color" content="#065f27" />
      </Head>
      <ToastContainer position="top-right" hideProgressBar={true} autoClose={2000} style={{ /* top: 24 * 4 */ /* 3 */ }} />
        <CookiesProvider>
          <AuthProvider>
      <QueryClientProvider client={client}>
            <ChangelogModalStateContext.Provider value={changelogState}>

              <Tooltip.Provider delayDuration={300}>
                <SkeletonTheme baseColor="#dddddd" highlightColor="#e8e8e8" duration={3}>
                  <ChangelogModal />
                  <NewlyRegisteredPopup key="newly-registered-popup" />
                  <Component {...pageProps} />
                </SkeletonTheme>
              </Tooltip.Provider>

            </ChangelogModalStateContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
          </AuthProvider>
        </CookiesProvider>

    </>
  );
}

export default App;
