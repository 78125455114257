import { fetchCurrentUser } from "api/user";
import { Userv2 } from "models/user";
import { useEffect, useState } from "react";
import { useAuthTokens } from "api/auth";
import { useAPIFetch } from "./fetch";
import { createAuthorizedFetch } from "api/fetch";

export function useUser(): [Userv2 | undefined, (v: Userv2 | undefined) => void] {
  // TODO: needs to be refactored into a context. Otherwise we create a new state each time and that's why nothing gets updated.
  const userS = useState<Userv2 | undefined>()
  const [tokens] = useAuthTokens();
  useEffect(() => {
    // console.log('tokens got updated', tokens)
    if (tokens?.accessToken) {
      fetchCurrentUser(createAuthorizedFetch(tokens?.accessToken)).then(v => {
        // console.log('not only updated but user too', v)
        userS[1](v)
      })
    }
    else userS[1](undefined)
  }, [tokens]) // TODO: fix infinite fetch

  return userS 
}
