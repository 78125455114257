import { IncomingMessage } from "http";
import { NextPageContext } from "next";
import { Cookies, useCookies } from "react-cookie";
import cookie from 'cookie'
import { FetchOptions } from "react-query/types/core/query";
import { PropsWithChildren, useContext, useMemo, useState } from "react";
import React from "react";

type TokenPair = {
  accessToken: string
  refreshToken: string
}

export const authCookieKeys = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token'
}




export function getRequestTokenPair(req: IncomingMessage): TokenPair | null {
  const cookies = cookie.parse(req.headers.cookie || "")
  const accessToken = cookies[authCookieKeys.accessToken]
  const refreshToken = cookies[authCookieKeys.refreshToken]

  if (!accessToken || !refreshToken)
    return null

  return { accessToken, refreshToken }
}

const AuthContext = React.createContext<[TokenPair | null, (v: TokenPair | null) => void]>([null, () => {}])

export function AuthProvider({children}: PropsWithChildren<{}>) {
  const [cookies, setCookie, removeCookie] = useCookies([...Object.values(authCookieKeys)])
  const [tokens, setTokens] = useState<TokenPair | null>(() => {
    const accessToken = cookies[authCookieKeys.accessToken]
    const refreshToken = cookies[authCookieKeys.refreshToken]

    return accessToken && refreshToken ? { accessToken, refreshToken } : null
  })

  return <AuthContext.Provider value={[tokens, setTokens]}>
    {children}
  </AuthContext.Provider>
}

export function useAuthTokens(): [TokenPair | null, (v: TokenPair | null) => void] {
  const [cookies, setCookie, removeCookie] = useCookies([...Object.values(authCookieKeys)])
  const [tokens, setTokens] = useContext(AuthContext)

  const setTokenPair = (v: TokenPair | null) => {
    if (!v) {
      removeCookie(authCookieKeys.accessToken, { path: '/' })
      removeCookie(authCookieKeys.refreshToken, { path: '/' })
      setTokens(null)
      return
    }
    setCookie(authCookieKeys.accessToken, v.accessToken, { path: '/', })
    setCookie(authCookieKeys.refreshToken, v.refreshToken, { path: '/' })
    setTokens(v)
  }
  // const tokens = useMemo(() => {

  // }, [cookies])

  return [tokens, setTokenPair]
}

export function isAuthorized(req: IncomingMessage): boolean {
  return !!getRequestTokenPair(req)
}
