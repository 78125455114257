import classNames from "classnames"
import { PropsWithChildren } from "react"
import ReactModal from "react-modal"
import { twMerge } from "tailwind-merge"
import { PropsWithClass, PropsWithClassAndStyle, PropsWithPrefixClassAndStyle } from "utils/props"


export type ModalWindowProps = {
  open: boolean
  close: () => void
}

export default function ModalWindow({ open, close, children, contentLabel, className}: PropsWithChildren<PropsWithClass<ModalWindowProps & { contentLabel?: string }>>) {
  return <ReactModal isOpen={open} contentLabel={contentLabel}
    overlayClassName="flex flex-row justify-center items-center w-screen h-screen fixed top-0 left-0 backdrop-brightness-50 z-50"
    className={twMerge("bg-transparent", className)}
    onRequestClose={close}
  >
    {children}
  </ReactModal>
}

export function NamedModalWindow({ name, children, className, style, titleClassName, titleStyle, close, closable, ...props }:
  PropsWithChildren<PropsWithClassAndStyle<ModalWindowProps & {
    name: string
    contentLabel?: string
    closable?: boolean
  }> & PropsWithPrefixClassAndStyle<{}, 'title'>>) {
  return <ModalWindow close={close} {...props}>
    <div className={twMerge(classNames("bg-white shadow-lg px-1 py-1 rounded-lg flex flex-col items-center gap-1"), className)} style={style}>
      <div className={classNames("flex flex-row w-full flex-none", closable ? "justify-between" : "justify-center")}>
        <h1 className={classNames("text-lg font-semibold text-center text-black", titleClassName)} style={titleStyle}>{name}</h1>
        {closable && <button onClick={close}>
          <img src="/images/close-black.svg" className="w-1 h-1" alt="Закрыть" />
        </button>}
      </div>
      {children}
    </div>
  </ModalWindow>
}



